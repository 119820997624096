<template>
  <div>
    <div class="uk-padding-small">
      <vk-breadcrumb class="uk-margin-remove">
        <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({name: 'properties'})">Liste des propriétés</vk-breadcrumb-item>
        <vk-breadcrumb-item :disabled="true">Edition de la propriété {{ property.id }}</vk-breadcrumb-item>
      </vk-breadcrumb>
    </div>
    <Property :property.sync="property"/>
    <div class="uk-padding-small uk-align-right">
      <vk-button
          :disabled="recordPending"
          style="border: solid black 1px"
          type="primary"
          @click="updateProperty"
      >
        Enregistrer
        <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
      </vk-button>
    </div>
  </div>
</template>

<script>
import backendApi from "@/backend/api";
import store from "@/store";
import Property from "@/components/Property";
import {mapActions} from "vuex";

export default {
  name: "PropertyEdit",
  components: {Property},
  data: () => ({
    recordPending: false,
    defaultProps: {
      property: Property.props.property.default,
    },
    property: Property.props.property.default()
  }),
  props: {},
  beforeRouteEnter(to, from, next) {
    backendApi
        .getProperty(store.state.login.user.token, to.params.propertyId)
        .then(data => {
          next(vm => {
            vm.property = data
          })
        })
        .catch(() => {
          next(false)
        })
  },
  methods: {
    updateProperty() {
      this.recordPending = true
      backendApi
          .updateProperty(store.state.login.user.token, this.property.id, this.property)
          .then(data => {
            this.property = data
            this.addNotification({message: 'Propriété mise à jour avec succès', status: 'success'})
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.recordPending = false
          })
    },
    ...mapActions('notifications', ['addNotification'])
  },
  mounted() {
  },
  updated() {
  },
  beforeUpdate() {
  },
  beforeCreate() {
  },
}
</script>

<style scoped>

</style>
